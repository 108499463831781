import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { useI18n } from '@paprika/l10n';
import GlobalErrorModal from 'components/modals/GlobalErrorModal';
import WebSocketService from 'services/WebSocketService';
import MessageAction from 'enums/MessageAction';
import CommandType from 'enums/CommandType';
import ToastKind from 'enums/ToastKind';
import MessageBodyComposer from 'utils/MessageBodyComposer';
import ContainerFiles from 'types/ContainerFiles';
import ExecutionResult from 'types/ExecutionResult';
import DownloadStatus from 'types/DownloadStatus';
import GlobalError from 'types/GlobalError';
import FlowNodeData from 'types/FlowNodeData';
import ScriptCell from 'types/ScriptCell';
import { ExecutionResultType, ExecutionOutput, ExecutionStatus } from 'enums/ExecutionResult';
import { updateFiles, setIsDownloadPending } from 'slices/FilesSlice';
import { addScriptCellOutput, endScriptCellExecution, startScriptCellExecution } from 'slices/ScriptCellsSlice';
import WebSocketLoginModals from 'components/modals/WebSocketLoginModals';
import WebSocketUtil from 'utils/WebSocketUtil';
import { useGlobalErrors } from './GlobalErrorsProvider';
import { useGlobalToast } from './GlobalToastProvider';
import { useApi } from './ApiProvider';

type WebSocketProviderValue = {
  isConnected: boolean;
  isExecuting: boolean;
  isFileDownloading: boolean;
  isPermanentlyDisconnected: boolean;
  sendResetMessage: () => void;
  sendRunFlowMessage: () => void;
  sendRunNodeMessage: (nodeId: string) => void;
  sendRunScriptCellMessage: (nodeId: string, cellId: string) => void;
  sendStopExecutionMessage: () => void;
  sendFileDownloadMessage: (fileName: string) => void;
};

export const WebSocketContext = React.createContext<WebSocketProviderValue | undefined>(undefined);

export function useWebSocket() {
  const context = React.useContext(WebSocketContext);
  if (context === undefined) {
    throw new Error('useWebSocket must be used within a WebSocketProvider');
  }
  return context;
}

export default function WebSocketProvider({
  analyticId,
  robotId,
  driveSystemUser,
  children,
}: {
  analyticId: string;
  robotId: string;
  driveSystemUser: string;
  children: React.ReactNode;
}) {
  const dispatch = useDispatch();
  const globalErrors = useGlobalErrors();
  const globalToast = useGlobalToast();
  const I18n = useI18n();
  const api = useApi();

  const [webSocketService, setWebSocketService] = useState<WebSocketService | undefined>(undefined);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isExecuting, setIsExecuting] = useState<boolean>(false);
  const [isFileDownloading, setIsFileDownloading] = useState<boolean>(false);
  const [isPermanentlyDisconnected, setIsPermanentlyDisconnected] = useState<boolean>(false);
  const [needsUserLogin, setNeedsUserLogin] = useState(false);
  const hasTriedReconnectAfterTokenRefresh = React.useRef<boolean>(false);
  const isUserLoginComplete = React.useRef<boolean>(false);

  const files = useSelector((state: RootState) => state.files);
  const flowVersion = useSelector((state: RootState) => state.highbondInfo.flowVersion);
  const flowNodeData: { [key: string]: FlowNodeData } = useSelector((state: RootState) => state.flowNodeData);
  const scriptCells: { [nodeId: string]: ScriptCell[] } = useSelector((state: RootState) => state.scriptCells.present);

  useEffect(() => {
    if (!webSocketService && analyticId) {
      const sessionId = WebSocketUtil.generateSessionId();
      if (WebSocketUtil.hasExistingSessionInBrowser(analyticId)) {
        const error: GlobalError = {
          title: I18n.t('global_error_modal.session_error_title'),
          message: I18n.t('global_error_modal.session_error_message'),
        };
        globalErrors.addCustomError(error);
      } else {
        const webSocketUrl = WebSocketUtil.getWebSocketUrl(window.location);
        const wss = new WebSocketService(webSocketUrl, sessionId, robotId, driveSystemUser);
        setWebSocketService(wss);

        WebSocketUtil.storeSessionIdUntilCloseTab(analyticId, sessionId);
        wss!.addMessageListener(MessageAction.ServiceReady, () => setIsConnected(true));
        wss!.addMessageListener(MessageAction.Result, handleResult);
        wss!.addMessageListener(MessageAction.ContainerFiles, handleContainerFiles);
        wss!.addMessageListener(MessageAction.DownloadStatus, handleDownloadStatus);
        wss!.addMessageListener('error', handleError);
        wss!.setOnCloseHandler(handleClose);
        wss!.setOnOpenHandler(handleOpen);

        function handleContainerFiles(containerFiles: ContainerFiles) {
          dispatch(updateFiles(containerFiles.files));
        }

        function handleResult(result: ExecutionResult) {
          if (result.category === ExecutionStatus.ExecutionStart) {
            dispatch(
              startScriptCellExecution({
                cellId: result.cellId,
                nodeId: result.nodeId,
              }),
            );
            setIsExecuting(true);
          }
          if (result.category === ExecutionStatus.ExecutionEnd) {
            dispatch(
              endScriptCellExecution({
                cellId: result.cellId,
                nodeId: result.nodeId,
                executionCount: result.executionCount,
              }),
            );
          }
          if (result.category === ExecutionStatus.ExecutionComplete) {
            setIsExecuting(false);
          } else if (result.type === ExecutionResultType.Output) {
            dispatch(
              addScriptCellOutput({
                cellId: result.cellId,
                nodeId: result.nodeId,
                output: { category: result.category as ExecutionOutput, content: result.content! },
              }),
            );
          }
        }

        function handleDownloadStatus(downloadStatus: DownloadStatus) {
          const { status, url, file: fileName } = downloadStatus;
          if (status === 'ok') {
            window.open(url, '_blank');
          } else {
            globalToast.setToast(I18n.t('global_toast.file_download_error'), ToastKind.Error, true);
          }
          dispatch(
            setIsDownloadPending({
              fileName,
              isDownloadPending: false,
            }),
          );
        }

        function handleError(error) {
          // if it's a 401, don't show an error modal. Instead refresh the token and attempt reconnect
          if (error.statusCode !== 401) {
            globalErrors.addCustomError({ title: I18n.t('global_error_modal.generic_title'), message: error.message });
          }
        }

        async function handleClose() {
          if (isUserLoginComplete.current || hasTriedReconnectAfterTokenRefresh.current) {
            globalErrors.addCustomError({
              title: I18n.t('global_error_modal.websocket_disconnected_title'),
              message: I18n.t('global_error_modal.websocket_disconnected_message'),
            });
            setIsPermanentlyDisconnected(true);
            return;
          }

          const tokenRefreshResponse = await api.tryTokenRefresh();
          if (tokenRefreshResponse.ok) {
            console.log(new Date().toLocaleTimeString(), 'token refresh is ok, initwebSocket');
            hasTriedReconnectAfterTokenRefresh.current = true;
            wss.initWebSocket();
          } else {
            setNeedsUserLogin(true);
          }
        }

        function handleOpen() {
          if (isUserLoginComplete.current) {
            globalToast.setToast(I18n.t('websocket_reconnected_notification'), ToastKind.Success, false);
            isUserLoginComplete.current = false;
          }
          hasTriedReconnectAfterTokenRefresh.current = false;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [I18n, analyticId, dispatch, webSocketService, isUserLoginComplete, hasTriedReconnectAfterTokenRefresh]); // globalErrors omitted to prevent infinite rerenders

  useEffect(() => {
    const isNodeExecuting =
      Object.values(flowNodeData).filter((flowNodeData) => flowNodeData.isPendingOutput).length > 0;
    const isAnyScriptCellExecuting =
      Object.values(scriptCells).filter(
        (scriptNodeCells) => scriptNodeCells.filter((cell) => cell.isPendingOutput).length > 0,
      ).length > 0;

    if (isNodeExecuting || isAnyScriptCellExecuting) setIsExecuting(true);
  }, [flowNodeData, scriptCells]);

  useEffect(() => {
    const isFileDownloading = files.some((file) => file.isDownloadPending);
    setIsFileDownloading(isFileDownloading);
  }, [files]);

  const value: WebSocketProviderValue | undefined = React.useMemo(() => {
    const sendRunFlowMessage = () =>
      webSocketService!.sendMessage({
        action: MessageAction.Command,
        type: CommandType.Execute,
        body: MessageBodyComposer.getRunFlowMessageBody(flowVersion),
      });

    const sendRunNodeMessage = (nodeId) =>
      webSocketService!.sendMessage({
        action: MessageAction.Command,
        type: CommandType.Execute,
        body: MessageBodyComposer.getRunNodeMessageBody(flowVersion, nodeId),
      });

    const sendRunScriptCellMessage = (nodeId, cellId) =>
      webSocketService!.sendMessage({
        action: MessageAction.Command,
        type: CommandType.Execute,
        body: MessageBodyComposer.getRunScriptCellMessageBody(flowVersion, nodeId, cellId),
      });

    const sendStopExecutionMessage = () =>
      webSocketService!.sendMessage({ action: MessageAction.Command, type: CommandType.StopExecution });

    const sendResetMessage = () =>
      webSocketService!.sendMessage({ action: MessageAction.Command, type: CommandType.Reset });

    const sendFileDownloadMessage = (fileName: string) => {
      dispatch(
        setIsDownloadPending({
          fileName,
          isDownloadPending: true,
        }),
      );
      webSocketService!.sendMessage({
        action: MessageAction.Command,
        type: CommandType.Download,
        body: {
          file: fileName,
        },
      });
    };

    return webSocketService
      ? {
          isConnected,
          isExecuting,
          isFileDownloading,
          isPermanentlyDisconnected,
          sendRunFlowMessage,
          sendRunNodeMessage,
          sendRunScriptCellMessage,
          sendStopExecutionMessage,
          sendResetMessage,
          sendFileDownloadMessage,
        }
      : undefined;
  }, [dispatch, flowVersion, isConnected, isExecuting, isFileDownloading, isPermanentlyDisconnected, webSocketService]);

  if (value) {
    function handleReconnect() {
      isUserLoginComplete.current = true;
      setNeedsUserLogin(false);
      webSocketService!.initWebSocket();
    }
    return (
      <WebSocketContext.Provider value={value}>
        {children}
        <WebSocketLoginModals needsUserLogin={needsUserLogin} onReconnect={handleReconnect} />
      </WebSocketContext.Provider>
    );
  }

  return <GlobalErrorModal />;
}
