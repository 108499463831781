import { combineReducers } from '@reduxjs/toolkit';
import undoable from 'redux-undo';
import edgesReducer from 'slices/EdgesSlice';
import filesReducer from 'slices/FilesSlice';
import flowNodesReducer from 'slices/FlowNodesSlice';
import flowNodeDataReducer from 'slices/FlowNodeDataSlice';
import scriptCellsReducer from 'slices/ScriptCellsSlice';
import highbondInfoReducer from 'slices/HighbondInfoSlice';
import robotReducer from 'slices/RobotSlice';
import robotVersionReducer from 'slices/RobotVersionSlice';
import usersReducer from 'slices/UsersSlice';
import variablesReducer from 'slices/VariablesSlice';

// TODO: add history size limits and filters
const rootReducer = combineReducers({
  highbondInfo: highbondInfoReducer,
  edges: undoable(edgesReducer),
  files: filesReducer,
  flowNodeData: flowNodeDataReducer,
  flowNodes: undoable(flowNodesReducer),
  scriptCells: undoable(scriptCellsReducer, {
    jumpToPastType: 'scriptCellsJumpToPast',
  }),
  robot: robotReducer,
  robotVersion: robotVersionReducer,
  users: usersReducer,
  variables: undoable(variablesReducer, {
    jumpToPastType: 'variablesJumpToPast',
  }),
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
