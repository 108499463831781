import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ActionCreators } from 'redux-undo';
import 'url-search-params-polyfill';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useI18n } from '@paprika/l10n';
import { RootState } from 'rootReducer';
import { useApi } from 'providers/ApiProvider';
import { useGlobalErrors } from 'providers/GlobalErrorsProvider';
import ChangeTracker from 'utils/ChangeTracker';
import GlobalNav from 'components/GlobalNav/GlobalNav';
import Canvas from 'components/Canvas/Canvas';
import GlobalErrorModal from 'components/modals/GlobalErrorModal';
import GlobalToast from 'components/GlobalToast/GlobalToast';
import { importFlowNodes } from 'slices/FlowNodesSlice';
import { importEdges } from 'slices/EdgesSlice';
import { importScriptCells } from 'slices/ScriptCellsSlice';
import { importVariables } from 'slices/VariablesSlice';
import { updateHighbondInfo } from 'slices/HighbondInfoSlice';
import { fetchSystemUsers } from 'slices/UsersSlice';
import { fetchRobot } from 'slices/RobotSlice';
import { fetchRobotVersion } from 'slices/RobotVersionSlice';
import HighbondInfo from 'types/HighbondInfo';
import FlowNode from 'types/FlowNode';
import RobotCategory from 'enums/RobotCategory';
import SpecialNodeKind from 'enums/SpecialNodeKind';
import { CurrentFlowVersion } from 'enums/FlowVersion';
import flippers from 'utils/flippers';
import FlowParser from 'utils/FlowParser';
import { getMonitorUrl } from 'utils/UrlBuilder';
import { isLocalDev } from 'localDev/localDevHelpers';
import WebSocketProvider from 'providers/WebSocketProvider';
import mockFlow from 'components/FlowDiagram/mockData/mockFlow';
import './App.scss';

export default function App() {
  const api = useApi();
  const dispatch = useDispatch();
  const globalErrors = useGlobalErrors();
  const I18n = useI18n();

  const [navData, setNavData] = useState<any>();
  const [flowName, setFlowName] = useState('');
  const [hasFetchError, setHasFetchError] = useState(false);
  const [analyticId, setAnalyticId] = useState('');

  const { orgId: orgIdInUrl, robotId, appId } = useParams();
  const { orgId }: HighbondInfo = useSelector((state: RootState) => state.highbondInfo);
  const { robot, error: fetchRobotError } = useSelector((state: RootState) => state.robot);
  useEffect(() => {
    function warnUserIfUnsavedChanges(e: BeforeUnloadEvent) {
      if (ChangeTracker.hasUnsavedChanges()) {
        // show browser modal
        e.preventDefault();
        e.returnValue = '';
      }
    }
    window.addEventListener('beforeunload', warnUserIfUnsavedChanges);

    return () => {
      window.removeEventListener('beforeunload', warnUserIfUnsavedChanges);
    };
  }, []);

  useEffect(() => {
    async function setGlobalNavData() {
      try {
        const data = await api.getGlobalNav(orgIdInUrl);
        addMonitorScript(); // Add monitor.js after global nav is done to make sure token is refreshed
        setNavData(data);

        const { locale, appSwitcherProps } = data;
        const robotAppLink = appSwitcherProps.apps.find((app) => app.key === 'robots');
        const encryptionKey = await api.getEncryptionKey(orgIdInUrl);
        const organizationName = appSwitcherProps.organizations.find(
          (org) => org.id === appSwitcherProps.initialOrganizationId,
        ).name;

        dispatch(
          updateHighbondInfo({
            locale,
            orgId: orgIdInUrl,
            robotUrl: robotAppLink.url,
            encryptionKey,
            organizationName,
          }),
        );
      } catch (statusCode) {
        globalErrors.addHttpError(statusCode);
      }
    }

    function addMonitorScript() {
      let monitorScriptTag;
      // insert pendo tracking script
      if (!isLocalDev()) {
        monitorScriptTag = document.createElement('script');
        monitorScriptTag.src = getMonitorUrl(window.location.origin);
        monitorScriptTag.type = 'text/javascript';
        monitorScriptTag.crossOrigin = 'use-credentials';
        document.head.appendChild(monitorScriptTag);
      }
    }

    setGlobalNavData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, dispatch]); // globalErrors omitted to prevent infinite rerenders

  useEffect(() => {
    if (orgId && robotId && appId) {
      dispatch(fetchRobot({ orgId, robotId }));
      dispatch(fetchRobotVersion({ orgId, robotId, appId }));
    }
  }, [dispatch, orgId, robotId, appId]);

  useEffect(() => {
    if (fetchRobotError) {
      setHasFetchError(true);
      globalErrors.addHttpError(fetchRobotError);
    }
  }, [fetchRobotError, globalErrors]);

  useEffect(() => {
    if (orgId && robot.category === RobotCategory.Workflow) {
      const { driveSystemUser } = robot;
      dispatch(fetchSystemUsers({ orgId, driveSystemUser }));
    }
  }, [dispatch, orgId, robot]);

  useEffect(() => {
    async function getAnalytics(orgId, appId) {
      try {
        const analytics = await api.getAnalytics(orgId, appId);
        if (analytics) {
          // For now, we have only 1 flow (analytic) per app
          const analytic = analytics[0];
          setFlowName(analytic.name);
          getFlow(analytic);
          setAnalyticId(analytic.id);
        }
      } catch (statusCode) {
        setHasFetchError(true);
        globalErrors.addHttpError(statusCode);
      }
    }

    async function getFlow(analytic) {
      const { raw: flow } = analytic;
      dispatch(updateHighbondInfo({ flowVersion: flow.version || CurrentFlowVersion }));
      dispatch(importScriptCells(FlowParser.getScriptCells(flow)));

      let nodes = FlowParser.getNodes(flow);
      let edges = FlowParser.getEdges(flow);

      if (!flippers.flowDiagram && nodes.length === 0) {
        nodes = [createScriptNode()];
      }

      if (flippers.flowDiagram) {
        nodes = mockFlow.data.nodes;
        edges = mockFlow.data.edges;
      }

      dispatch(importFlowNodes(nodes));
      dispatch(importEdges(edges));
      dispatch(importVariables(FlowParser.getVariables(flow)));
      dispatch(ActionCreators.clearHistory());
      ChangeTracker.saveChanges();
    }

    // this is to show some dummy nodes in the flow
    function createScriptNode(): FlowNode {
      return {
        name: I18n.t(`nodes.script`),
        libraryNodeId: 'script-id',
        kind: SpecialNodeKind.Script,
        id: uuidv4(),
        timestamp: 0,
        position: {
          x: 0,
          y: 0,
        },
      };
    }

    if (api && orgId && appId && robot.id) {
      getAnalytics(orgId, appId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, dispatch, orgId, appId, robot.id]); // globalErrors omitted to prevent infinite rerenders

  return (
    <div className="layout">
      <WebSocketProvider analyticId={analyticId} robotId={robot.id} driveSystemUser={robot.driveSystemUser}>
        <GlobalNav navData={navData} />
        {!hasFetchError && <Canvas flowName={flowName} robotName={robot ? robot.name : ''} />}
        <GlobalToast />
        <GlobalErrorModal />
      </WebSocketProvider>
    </div>
  );
}
